.bg-color {
    color: #75B317
}

.about-hero {
    background-image: url(../Images/bg-image/about-bg.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 400px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.about-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.hero-text {
    overflow: hidden;
}

/* .custom-container {
    z-index: 9999
} */

.bg-custom-title .title {
    font-size: 70px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 60px
}

.bg-custom-title .bold-title {
    font-weight: 900
}

.img-fix {
    height: 100%;
    width: 70% !important
}

.about-image-content {
    display: flex;
    align-items: center;
    justify-content: center
}

.user-name {
    font-size: 22px;
    font-weight: 700
}

.rating-box {
    padding: 16px 24px;
    text-align: center
}

.W-90 {
    width: 40%
}

.rating-tltle h2 {
    font-size: 26px;
    font-weight: 600
}

.rating-image {
    margin-bottom: 20px
}

.Rated {
    background: #fff;
    padding: 20px 0
}

section.get-quote {
    background: #75b317;
    padding: 60px 0
}

.quote-title {
    font-size: 36px;
    font-weight: 700;
    color: #fff
}

.quote-btn {
    float: right
}

.quote-btn .custom-btn {
    color: #75B317;
    border: 3px solid #fff;
    background-image: -webkit-linear-gradient(30deg, #fff 50%, transparent 50%);
    background-image: linear-gradient(30deg, #fff 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 20px 40px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
}

.quote-btn .custom-btn:hover {
    background-position: 100%;
    color: #fff
}


.client-box {
    text-align: center
}

.custom-title .title2 {
    font-size: 70px;
    font-weight: 500;
    color: #2C2C2C;
    margin: 40px 0
}

.contact-section {
    background-image: url(../../assets/Images/bg-image/footer-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding: 80px 0;
    background-size: cover
}

.custom-title .title2 .text-bold {
    font-weight: 800;
    font-size: 70px
}

.form-content {
    background: #fff;
    padding: 40px 50px;
    border-radius: 25px
}

.form-title {
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 40px
}

.our-tusrt-tltle {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px
}

.love-warpper {
    margin-top: 50px
}

.lp-element-box {
    border: 3px solid #75b317;
    border-radius: 10px;
    padding: 26px;
    text-align: center
}

.icon {
    margin-bottom: 12px
}

.lp-element-title {
    font-size: 32px;
    font-weight: 500
}

.lp-element-title .highlight {
    font-weight: 800
}

@media (max-width:767px) {
    .custom-section {
        padding: 40px 0 0
    }

    .about-image-content {
        margin-bottom: 40px
    }

    .about-text-content {
        margin-bottom: 40px
    }

    .custom-section-mr {
        margin: 40px 0 0
    }

    .rating-box {
        padding: 20px 10px;
        text-align: center
    }

    section.get-quote {
        background: #75b317;
        padding: 40px 0
    }

    .quote-title {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        text-align: center
    }

    .quote-btn {
        text-align: center;
        margin: 40px 0;
        float: initial
    }

    .contact-section {
        padding: 40px 0
    }

    .custom-title .title2 {
        font-size: 36px;
        font-weight: 500;
        color: #2C2C2C;
        margin: 0 0 40px;
        text-align: center
    }

    .custom-title .title2 .text-bold {
        font-weight: 800;
        font-size: 36px
    }

    .our-tusrt-tltle {
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 24px
    }

    .lp-element-box {
        margin-bottom: 20px
    }

    .about-content {
        padding-top: 90px
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .about-content {
        padding-top: 90px
    }

    .quote-title {
        font-size: 36px;
        font-weight: 700;
        color: #fff
    }

    .custom-title .title2 {
        text-align: center
    }

    .contact-section {
        padding: 40px 0
    }

    .lp-element-box {
        margin-bottom: 20px
    }

    .about-content {
        padding-top: 90px
    }
}

@media only screen and (min-width:280px) and (max-width:403px) {
    .contact-form .form-content {
        padding: 24px
    }
}


@media only screen and (min-width:993px) and (max-width:1199px) {
    .form-content {
        background: #fff;
        padding: 30px 40px;
        border-radius: 25px;
    }
 }
