.Pricing-Active-bttn {
    background-color: #E8E6FC !important;
    color: #7367F0 !important;
    border: none !important;
    border-radius: 4px;
    padding: 3px 10px;
    font-weight: 600;
}

.Delete-Active-bttn {
    background-color: #EA5455 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px;
    padding: 0px 7px 5px;
    font-size: 20px;
}

.Edit-Active-bttn{
    background-color: #8178DB !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px;
    padding: 0px 7px 5px;
    font-size: 20px;
}

.priceFormatterp .priceFormatterp-img{
    width: 32px;
    height: 32px;
}

.Enable-Active-bttn {
    background-color: #75b317 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 50px;
    padding: 7px 14px;
    font-weight: 500;
    font-size: 12px;
}

.react-bootstrap-table-pagination-list{
    display: flex;
    justify-content: end;
}

.Clr-Green span {
    color: #7D7A8A;
    font-weight: 400;
}

.Clr-Green {
    color: #75b317 !important;
    font-weight: 600 !important;
}