.map-box {
  height: 100%;
}

/* Add your own styles here */
.info-window-content {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.info-window-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.info-window-description {
  font-size: 14px;
}
