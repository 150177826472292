.table-card {
    background: #fff;
    padding: 20px;
    border-color: transparent;
}

h5.card-title {
    font-size: 20px;
    color: #6F6B7D;
    margin-bottom: 18px;
    font-weight: 600;
}

.Yes-Active-bttn{
    background-color: #75b317 !important;
    color: #fff !important;
    padding: 3px 14px;
    border-radius: 50px;
    border: 1px solid #75b317 !important;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.scroll-tab {
    height: calc(100vh - 30vh);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 20px;
}

.full-width-modal .modal-dialog {
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 100% !important;
    height: 100%;
    width: 100%;
}

.btn-icon img {
    width: 20px;
    height: 20px;
}

.resize_btn {
    background-color: #75b317 !important;
    color: #fff !important;
    padding: 8px 24px;
    border-radius: 5px;
    border: 1px solid #75b317;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    gap: 10px;
}

.create-stax-id-btn {
    float: right;
}

.invoice-list-btn {
    width: max-content;
    border-radius: 4px;
}

.booking-appoinment-card.booking-green .booking-appoinment-card-header {
    background-color: #568f00;
    color: #fff;
}

.booking-appoinment-card.booking-green {
    background-color: #75b317;
    color: #fff;
}

.booking-appoinment-card.booking-green h5 {
    color: #fff;
}

.booking-appoinment-card.booking-green .booking-appoinment-card-header h5, .booking-appoinment-card.booking-green .booking-appoinment-card-header p {
    color: #fff;
}




.booking-appoinment-card.booking-yellow .booking-appoinment-card-header {
    background-color: #ffc300;
    color: #fff;
}

.booking-appoinment-card.booking-yellow {
    background-color: #ffd60a;
}

button.input_edit_btn {
    width: 40px;
    background: #689f15;
    color: #fff;
    border: none;
    font-size: 20px;
}

.edit-email-popup {
    position: absolute;
    top: 40%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 9999;
    width: 400px;
    background: #fff;
    padding: 24px 24px;
    box-shadow: 0px 0px 3px 0px #000;
    height: auto;
}

.edit-email-popup-inner-body-footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.edit-email-popup-inner-body-footer button {
    color: #fff !important;
    border: none;
    padding: 5px 16px;
    border-radius: 5px;
    cursor: pointer;
}

.edit-email-popup-inner-body-footer button.Save_btn {
    background: #75b317 !important;
}

.edit-email-popup-inner-body-footer button.cancel_btn {
    background: #f00 !important;
}

.edit-email-popup-inner-header h4 {
    font-size: 20px;
    color: #6F6B7D;
    margin-bottom: 18px;
    font-weight: 600;
}

/* .popup_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
} */

/* .booking-appoinment-card.booking-yellow h5 {
    color: #fff;
}

.booking-appoinment-card.booking-yellow .booking-appoinment-card-header h5, .booking-appoinment-card.booking-yellow .booking-appoinment-card-header p {
    color: #fff;
} */