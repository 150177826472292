.Topnavigation-container .Topnavigation-containt-header h1 {
    font-size: 28px;
    font-weight: 600;
    color: #2c2c2c
}

.Topnavigation-container .Topnavigation-containt-header img {
    width: 24px;
    height: auto
}

.Topnavigation-container {
    margin: 0 30px;
    background-color: #f4f4f4;
    padding-bottom: 13px
}

.Book-Appointment {
    font-size: 18px;
    background-color: #ed1c24 !important;
    font-weight: 600;
    border: 1px solid #e83d3e;
    border-radius: 45px;
    color: #fff !important;
    height: 50px;
    padding: 0 27px;
}

.Book-Appointment:hover {
    background-color: #E83D3E;
    color: #fff
}

.Topnavigation-containt-header span {
    font-size: 20px;
    font-weight: 800;
    color: #2c2c2c
}


@media only screen and (min-width:280px) and (max-width:374px) { 
    .Book-Appointment {
        font-size: 16px;
        background-color: #ed1c24!important;
        font-weight: 600;
        border: 1px solid #e83d3e;
        border-radius: 35px;
        color: #fff;
        height: 43px;
        padding: 0 18px;
    }
}