.contact-us-section {
    margin: 100px 0
}

.submit-btn-contact-us {
    color: #fff;
    border: 3px solid #75B317 !important;
    background-image: -webkit-linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-image: linear-gradient(30deg, #75B317 50%, transparent 50%);
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    padding: 16px 40px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
}

.submit-btn-contact-us:hover {
    background-position: 100%;
    color: #75B317;
}

.Contact-us-hero {
    background-image: url(../../assets/Images/bg-image/bo01.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center
}

.Contact-us-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.Contact-us-form {
    padding: 25px 40px;
    border: 2px solid #75b317
}

.Contact-us-form .content-body-form {
    margin-bottom: 30px
}

.Contact-us-form .content-body-form form .form-group .form-label {
    font-size: 18px;
    font-weight: 600;
    color: #030014
}

.Contact-us-form .content-body-form form .form-group .form-control {
    border: 1px solid #75b317;
    font-size: 18px;
    font-weight: 300;
    color: #030014
}

.view-all-reviews-btn {
    text-align: end
}

.view-all-reviews-btn .All-Reviews-btn {
    text-decoration: none;
    color: #75B317;
    font-size: 18px;
    font-weight: 600
}

.cstm-mrgn {
    margin-bottom: 100px
}

@media (max-width:767px) {
    .Contact-us-hero {
        background-position: center;
        height: 350px
    }

    .contact-us-section {
        margin: 60px 0
    }

    .Contact-us-form {
        padding: 16px
    }

    .Contact-us-form .content-body-form {
        margin-bottom: 18px
    }

    .submit-btn-contact-us {
        background-color: #75B317;
        color: #fff;
        padding: 12px 0;
        border-radius: 0;
        font-size: 18px;
        font-weight: 600;
        transition: 0.4s;
        width: 100%
    }

    .cstm-mrgn {
        margin-bottom: 60px
    }
}