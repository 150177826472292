.booking-form-section {
    padding-top: 90px
}

.booking-warrper-40 {
    margin-top: 40px
}

.booking-30 {
    margin-top: 30px
}

.booking-form-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 50px;
    border: none
}

.booking-form-box {
    margin-top: 70px
}

.select-box {
    border: 2px solid #75B317;
    padding: 16px 40px
}

.select-box.active {
    background-color: #EAF4DC;
}

.select-box2.active {
    background-color: #EAF4DC;
}

.select-box-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

.select-box-inner-title {
    font-size: 18px;
    font-weight: 700;
    color: #75b317;
    margin-bottom: 8px
}

.use-zip-code {
    margin-top: 40px
}

.booking-custom-lable {
    font-size: 18px;
    color: #75b317;
    font-weight: 600
}

.booking-custom-input {
    height: 50px;
    border: 1px solid #EDEDED;
    border-radius: 5px
}

.search-btn {
    background: #75b317;
    height: 50px;
    display: flex;
    justify-content: center
}

.search-btn-custom {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    width: 100%
}

.search-btn-custom:hover {
    color: #fff;
    font-size: 20px;
    font-weight: 500
}

.App {
    text-align: center
}

.Map {
    width: 100%;
    height: 400px
}

.Map-view {
    margin-top: 40px
}

.App-logo {
    height: 40vmin;
    pointer-events: none
}

.serviceoption.active {
    display: block !important;
}

.form-check-input:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(117 179 23 / 25%);
}

@media (prefers-reduced-motion:no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff
}

.App-link {
    color: #61dafb
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.step-count-inner {
    display: flex;
    align-items: center
}

.step-count-inner-title {
    font-size: 26px;
    font-weight: 600
}

.Step-icon {
    background: #75b317;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    font-weight: 600;
    width: 34px;
    height: 34px;
    justify-content: center;
    margin-right: 10px
}

.selexted-service-check {
    margin-right: 10px
}

.Service-details-box-inner-title {
    font-size: 20px;
    color: #75b317;
    font-weight: 700;
    margin-bottom: 16px;
}

.custom-select-box {
    border: 1px solid #EDEDED;
    height: 44px
}

.Service-details-box-inner.extra-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fbfbfb;
    padding: 10px 12px
}

.add-item-btn {
    background: #75b317 !important;
    border: 1px solid #75b317 !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    opacity: 1 !important;
}

.add-item-btn:hover {
    background: #75b317;
    border: 1px solid #75b317
}

.extra-service-value {
    margin: 0 14px;
    font-size: 20px;
    font-weight: 600
}

.booking-card-btn-footer {
    background: transparent;
    border: none;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.wizard-btn {
    background: #75b317 !important;
    border: 1px solid #75b317 !important;
    padding: 8px 34px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0
}

.wizard-btn:hover {
    background: #75b317 !important;
    border: 1px solid #75b317 !important
}

.wizard-submit-btn {
    background: #75b317 !important;
    border: 1px solid #75b317 !important;
    padding: 8px 34px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0
}

.wizard-submit-btn:hover {
    background: #75b317 !important;
    border: 1px solid #75b317 !important
}

.technician-profile {
    width: 160px;
    height: 160px;
    border: 1px solid #3F4B5E;
    border-radius: 10px;
}

.technician-profile {
    text-align: center;
    padding: 20px 0
}

.technician-profile.active {
    border: 3px solid #75b317;
}

.technician-profile-name {
    margin-top: 14px;
}

.technician-profile-name h4 {
    font-weight: 600;
    font-size: 14px;
    color: #75b317;
}

.technician-list {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap
}

.technician-profile-btn {
    background: #fff !important;
    padding: 0px !important;
    border-radius: 0;
    border: none;
    margin: 0 5px 14px 0;
    color: #000;
}

.technician-profile-btn:hover {
    background: transparent;
    border: none;
    color: #000
}

.calender-box {
    margin-top: 30px
}

.time-box {
    background: #fbfbfb;
    padding: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.time-btn {
    width: 100px;
    height: 40px;
    margin: 0 1px 10px;
    background: #fff !important;
    border: 1px solid #B0B0B0 !important;
    color: #000;
}

.time-btn:hover {
    background: #75B317 !important;
    border: 1px solid #75B317 !important;
    color: #fff
}

.booking-card {
    background: #fff;
    padding: 30px 30px
}

.booking-card-header {
    display: flex;
    justify-content: space-between
}

.booking-card-title {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c
}

.booking-card-subtitle {
    color: #75B317;
    font-weight: 700
}

.booking-card-time {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c
}

.booking-card-body {
    margin: 24px 0
}

.booking-card-body-item-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 7px;
}

.booking-card-service-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 7px;
}

.multi-service {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.booking-card-header-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px
}

.booking-card-footer-item-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px
}

.sub-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px
}

.booking-card-footer-item-subtitle {
    font-size: 16px;
    font-weight: 700
}

.booking-card-footer-item-price {
    font-size: 16px;
    font-weight: 700;
    color: #75b317
}

.booking-left {
    position: relative
}

/* .booking-left::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 110%;
    width: 2px;
    height: 60px;
    background: #75b317;
    z-index: 1;
} */

/* .booking-sidebar {
    position: relative;
    width: -moz-fit-content;
    width: 378px
} */

.select-box-icon svg {
    width: 30px;
    height: 30px;
    color: #a1a1a1;
}

.select-box.active .select-box-icon svg {
    color: #75b317
}

.select-box-2.active .select-box-icon svg {
    color: #75b317
}

.select-type-btn {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
}

.select-box-content {
    text-align: left;
}

.selexted-service-check svg {
    width: 26px;
    height: 26px;
    color: #a1a1a1;
}

.selexted-service-check.active svg {
    color: #75b317
}

.quantity-picker {
    display: inline-block;
    border: none !important;
}

.quantity-modifier {
    font-size: 1.5rem;
    background: #75b317 !important;
    color: #fff !important;
    border: 0 solid #dbdbdb;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    height: 40px !important;
    width: 40px !important;
}

.quantity-display {
    width: 4rem;
    font-size: 1.5rem;
    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
    background: transparent;
}

.Service-details-box-inner {
    margin-bottom: 20px;
}

.technician-profile-btn.active .technician-profile {
    border: 1px solid #75b317;
    background-color: #75b317;
    color: #fff;
}

.technician-profile-btn.active .technician-profile .technician-profile-name h4 {
    color: #fff;
}

@media (max-width:767px) {
    .booking-sidebar {
        display: none
    }

    .time-btn {
        width: 88px;
        height: 40px;
        margin: 0 2px 10px;
        background: #fff !important;
        border: 1px solid #B0B0B0 !important;
        color: #000;
        padding: 0px;
    }

    .step-count-inner {
        display: flex;
        align-items: baseline;
    }
}

.calenderHideShow.active{
    display: block !important;
}

.availablestafftime.active{
    display: block !important;
}
.time-btn.active {
    background: #77b41a !important;
}

@media only screen and (min-width:280px) and (max-width:377px) {
    .technician-profile-img img {
        width: 70px
    }

    .technician-profile {
        width: 120px;
        height: 120px;
        border: 1px solid #3F4B5E;
        border-radius: 10px
    }

    .booking-form-card {
        padding: 0
    }

    .technician-profile-name {
        margin-top: 10px
    }

    .technician-profile-name h4 {
        font-weight: 600;
        font-size: 17px
    }

    .technician-profile-btn {
        margin: 0 8px 16px 0
    }

    .technician-profile {
        text-align: center;
        padding: 10px 0
    }

    .technician-list {
        justify-content: center
    }

    .wizard-submit-btn {
        font-size: 14px;
        padding: 8px 16px
    }

    .wizard-btn {
        font-size: 14px;
        padding: 8px 16px
    }

    .select-box {
        background-color: #EAF4DC;
        border: 2px solid #75B317;
        padding: 16px;
        margin-bottom: 16px
    }

    .step-count-inner-title {
        font-size: 22px;
        font-weight: 600;
    }
}

@media only screen and (min-width:378px) and (max-width:425px) {
    .booking-form-card {
        padding: 0
    }

    .select-box {
        padding: 16px;
        margin-bottom: 14px
    }

    .use-zip-code {
        margin-top: 22px
    }

    .select-box-inner-content p {
        font-size: 14px
    }

    .technician-profile-btn {
        margin: 0 8px 16px 0
    }

    .technician-profile {
        width: 150px;
        height: 150px;
        border: 1px solid #3F4B5E;
        border-radius: 10px
    }

    .technician-profile {
        text-align: center;
        padding: 6px 0
    }

    .technician-profile-name {
        margin-top: 10px
    }

    .technician-profile-name h4 {
        font-weight: 600;
        font-size: 17px
    }

    .technician-list {
        justify-content: center
    }

    .select-box {
        padding: 16px 40px;
        margin-bottom: 16px
    }

    .step-count-inner-title {
        font-size: 22px;
        font-weight: 600;
    }
}

@media only screen and (min-width:427px) and (max-width:792px) {
    .booking-form-card {
        padding: 30px 30px
    }

    .select-box {
        padding: 16px 24px;
        margin-bottom: 16px
    }
}


@media only screen and (min-width:961px) and (max-width:1199px) { 
    .nav-links {
        color: #2c2c2c;
        text-decoration: none;
        padding: 0.5rem 0.2rem;
    }

    .custom-container {
        margin:0 80px ;
    }

    .select-box {
        margin-bottom: 16px;
    }
}

@media only screen and (min-width:1200px) and (max-width:1399px) { 
    .select-box {
        border: 2px solid #75B317;
        padding: 16px 20px;
    }
}


@media only screen and (min-width:763px) and (max-width:990px) { 
    .select-box {
        margin-bottom: 16px;
    }
}

@media only screen and (min-width:992px) and (max-width:1200px) { 
    .technician-profile {
        width: 150px;
        height: 150px;
        border: 1px solid #3F4B5E;
        border-radius: 10px;
    }

    .technician-profile {
        text-align: center;
        padding: 6px 0;
    }
}



.modal-confirm {		
	color: #636363;
	width: 325px;
	font-size: 14px;
}
.modal-confirm .modal-content {
	padding: 20px;
	border-radius: 5px;
	border: none;
}
.modal-confirm .modal-header {
	border-bottom: none;   
	position: relative;
}
.modal-confirm h4 {
	text-align: center;
	font-size: 26px;
	margin: 30px 0 -15px;
}
.modal-confirm .form-control, .modal-confirm .btn {
	min-height: 40px;
	border-radius: 3px; 
}
.modal-confirm .close {
	position: absolute;
	top: -5px;
	right: -5px;
}	
.modal-confirm .modal-footer {
	border: none;
	text-align: center;
	border-radius: 5px;
	font-size: 13px;
}	
.modal-confirm .icon-box {
	color: #fff;		
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: -70px;
	width: 95px;
	height: 95px;
	border-radius: 50%;
	z-index: 9;
	background: #82ce34;
	padding: 15px;
	text-align: center;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
	font-size: 58px;
	position: relative;
	top: 3px;
}
.modal-confirm.modal-dialog {
	margin-top: 80px;
}
.modal-confirm .btn {
	color: #fff;
	border-radius: 4px;
	background: #82ce34;
	text-decoration: none;
	transition: all 0.4s;
	line-height: normal;
	border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
	background: #6fb32b;
	outline: none;
}
.trigger-btn {
	display: inline-block;
	margin: 100px auto;
}

.extra-service-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;
}

.booking-warrper-mb-40 {
    margin-bottom: 40px;
}

.form-error {
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}

.payment-card-details {
    width: -webkit-fill-available;
}

.add-card-body {
    background: #75b31761;
    color: #000;
}

.extra-service .row hr:last-child:nth-last-child(1) {
    display: none;
}

.Service-details-box-inner-title2 {
    font-size: 18px;
    color: #75b317;
    font-weight: 700;
    margin-bottom: 16px;
}

.bg-light-color {
    background: #fbfbfb;
    padding-top: 10px;
}