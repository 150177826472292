#header {
    /* position: absolute; */
    width: 260px;
}

#header .pro-sidebar {
    height: 100vh;
}

#header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 20px;
    cursor: pointer;
}

#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

#header .pro-sidebar-inner {
    background-color: white;
}

/* #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
} */

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 18px 18px;
    color: #000;
    font-weight: bold;
    margin-bottom: 0 !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 14px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #4B465C;
    margin: 4px 0px;
    font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent !important;
    color: #4B465C;
    border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon svg {
    width: 22px;
    height: 22px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
    background: #F8F8F8 !important;
    border-radius: 6px;
}

#header .logo {
    padding: 20px;
}

.title-menu-section {
    font-size: 13px;
    padding: 14px 30px 14px 20px;
    color: #4B465C;
    font-weight: 500;
}

.pro-sidebar.collapsed .title-menu-section {
    display: none;
}

.pro-sidebar .pro-menu a:before {
    content: "";
    position: relative !important;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: #F8F8F8;
    border-radius: 6px 0px 0px 6px;
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 35px 8px 5px;
    cursor: pointer;
}

.logotext img.logo01 {
    padding: 18px;
    width: 200px;
    height: auto;
}

.logotext img.logo02 {
    padding: 18px;
    width: 72px;
    height: auto;
}

.pro-sidebar-layout::-webkit-scrollbar {
    width: 6px;
}

.pro-sidebar-layout::-webkit-scrollbar {
    display: none;
}
 
.pro-sidebar-layout::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #b3b3b3;
}

@media only screen and (max-width: 720px) {
    html {
        overflow-x: hidden;
    }
}

.technician-tab-link.nav-link:hover .user-profile-box {
    background-color: #75b317;
    color: #fff;
}

.technician-tab-link.nav-link:hover{
    color: #75b317;
}

.technician-tab-link.nav-link.active .user-profile-box {
    background-color: #75b317;
    color: #fff;
}
.closemenu-mobile {
    display: none;
}


.logout-btn {
    background: transparent !important;
    border: none;
    padding: 0px;
}

.logout-btn:hover {
    background: transparent !important;
    border: none;
    padding: 0px;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: #fbfbfb;
    border-radius: 5px;
}

.notification_btn {
    background-color: #75b317;
    border: 1px solid #75b317;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    line-height: 16px;
    position: relative;
}

.custom-notification-container {
    position: absolute;
}

.notification_btn .pro-menu-item .pro-inner-item {
    color: #fff !important;
    padding: 0 !important;
    margin: 0 !important;
}

.notification_btn .pro-menu-item .pro-inner-item svg {
    width: 18px;
    height: 18px;
}

.notification-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
    /* border-bottom: 1px solid #ccc; */
    padding: 10px 10px 6px;
}

.notification-modal-header h5 {
    font-size: 22px;
    font-weight: bold;
}

.notification-modal .modal-dialog {
    max-width: 450px !important;
}

.notification-modal .modal-dialog .modal-content {
    border-radius: 8px;
    overflow: hidden;
}

.notification-modal .modal-dialog .modal-content .modal-body {
    padding: 0;
}

.notification-card {
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
    background: #fbfbfb;
}

.notification-card h6 {
    margin-bottom: 0;
    font-size: 16px;
}

.notification-card p {
    margin-bottom: 0;
    font-size: 12px;
}

button.accept {
    background: #75b317;
    border: 1px solid #75b317;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
}

button.reject {
    background: #ffffff;
    border: 1px solid #4B465C;
    color: #4B465C;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
}

button.reject:hover {
    background-color: #75b317;
    color: #ffffff;
}

.notification-modal-header svg {
    cursor: pointer;
}

.notification-modal-body {
    margin: 0px 0px 10px;
}

.notification-card:nth-last-child(1) {
    border-bottom: none;
}

.notification-modal-footer {
    border-top: 1px solid #ccc;
    padding: 10px 20px;
}

.view-all-btn {
    background: transparent !important;
    border: none;
    padding: 0px;
    color: #75b317;
    font-weight: 600;
    font-size: 15px;
}

.view-all-btn:hover {
    color: #75b317 !important;
}

@media only screen and (min-width:280px) and (max-width:992px) {
    .sidebar-container {
        display: none;
    }

    .sidebar-container.new-class-name {
        display: block;
        z-index: 1;
    }

    .sidebar-container.new-class-name::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        z-index: 100;
    }

    .closemenu {
        display: none;
    }

    .closemenu-mobile  {
        display: block;
        position: absolute;
        right: 0;
        z-index: 9;
        line-height: 20px;
        border-radius: 50%;
        font-weight: bold;
        font-size: 22px;
        top: 20px;
        cursor: pointer;
    }

    .sidebar-container.class-none {
        display: none;
    }

    #header .pro-sidebar {
        height: 100vh;
    }

    .stop.new-class-name {
        overflow: hidden;
    }
}


