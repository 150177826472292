.custom-accordion-item {
    border-top: 1px solid #DBDADE !important;
    border-bottom: 1px solid #DBDADE !important;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
}

.accordion-button {
    color: #6F6B7D;
    font-weight: 600; 
}

.accordion-button:not(.collapsed) {
    color: #6F6B7D;
    background-color: #fff;
    border-color: #DBDADE;
}

.accordion-button:focus {
    box-shadow: none;
}

.non-edit-input-box {
    background: #8f85f370;
    color: #8F85F3!important;
}

.booking-appoinment-card {
    padding: 0 !important;
}

.booking-appoinment-card {
    background: #f5f7fb;
    border-radius: 6px;
    margin-bottom: 10px;
}

.booking-appoinment-card-header {
    background: #D9D9D9;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 8px 14px;
    border-radius: 6px 6px 0px 0px;
}

.booking-appoinment-card-header-left-title h5 {
    color: #4B465C;
    font-size: 20px;
    font-weight: 600;
}

.booking-appoinment-card-header-left-title p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}

.appointment-btn-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #75b317;
    padding: 4px 7px;
    border-radius: 50px;
    margin-left: 8px;
    width:  fit-content;
}

.appointment-btn-item-text {
    margin: 0px 8px;
    color: #fff;
}

.appointment-btn-item-text p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}

.booking-appoinment-card-header-btn {
    display: flex;
}

.booking-appoinment-plx-title {
    font-size: 16px;
    color: #4B465C;
    font-weight: 600;
}

.booking-appoinment-card-body {
    padding: 5px 10px;
}

.booking-appoinment-plx-details-item {
    display: flex;
    align-items: center;
}

.booking-appoinment-plx-details-item-right {
    margin-left: 10px;
}

.user-status {
    color: #EA5455;
    font-weight: 600;
    background: #ea545524;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 4px;
    width: min-content;
}

.user-name {
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: 600;
}

.acc-custom-btn {
    color: #fff;
    padding: 7px 24px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    border-color: transparent;
}


.booking-appoinment-card-footer {
    padding: 5px 10px 14px;
}

.booking-appoinment-btn-list {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}