.table-card {
    background: #fff;
    padding: 20px;
    border-color: transparent;
}

h5.card-title {
    font-size: 20px;
    color: #6F6B7D;
    margin-bottom: 18px;
    font-weight: 600;
}

.Yes-Active-bttn {
    background-color: #75b317 !important;
    color: #fff !important;
    padding: 3px 14px;
    border-radius: 50px;
    border: 1px solid #75b317 !important;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.scroll-tab {
    height: calc(100vh - 30vh);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 20px;
}

.full-width-modal .modal-dialog {
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 100% !important;
    height: 100%;
    width: 100%;
}

.btn-icon img {
    width: 20px;
    height: 20px;
}

.resize_btn {
    background-color: #75b317 !important;
    color: #fff !important;
    padding: 8px 24px;
    border-radius: 5px;
    border: 1px solid #75b317;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    gap: 10px;
}

.create-stax-id-btn {
    float: right;
}

.invoice-list-btn {
    width: max-content;
    border-radius: 4px;
}

.form-rating svg {
    font-size: 22px;
    color: #75b317;
    margin-right: 5px;
}