.Herosection-container-containt {
    background-image: url(../../../assets/Images/bg-image/about-bg.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 400px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background-size: cover
}

.Herosection-container-containt::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.Herosection-container-containt .bg-custom-title .title {
    font-size: 50px;
    font-weight: 700
}

.hero-bottom {
    background-color: #EAEAEA;
    border-radius: 0 0 25px 25px
}

.hero-bottom-margin {
    margin: 0 100px
}


@media only screen and (min-width:320px) and (max-width:767px) {
    .Herosection-container-containt {
        height: 150px
    }

    .Herosection-container-containt .bg-custom-title .title {
        font-size: 40px
    }
}

@media only screen and (min-width:280px) and (max-width:992px) {
    .icn-width {
        width: 18px !important;
        height: auto;
    }
}