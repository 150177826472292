.header-drop-btn {
    background: transparent !important;
    border: none !important;
    padding: 0px !important;
    width: 100%;
}


.navbar-toggler {
    padding: 0px;
    border: none;
    border-color: transparent;
    box-shadow: none !important;
    margin-right: 24px;
}

.header-custom-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-expand-lg {
    display: none;
}

@media only screen and (min-width:280px) and (max-width:992px) {

    .navbar {
        padding-top: 4px;
        padding-bottom: 4px;
    }


    .navbar-nav img {
        width: 170PX;
        height: auto;
    }

    .navbar-expand-lg {
        display: block;
        margin-bottom: 20px;
    }


}
