.dashboard-card {
    border: none;
    background: #fff;
    padding: 24px 16px;
    height: 100%;
}

.card-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-left-content p {
    font-size: 15px;
    margin-bottom: 6px;
    color: #4B465C;
    font-weight: 500;
}

.card-left-content h5 {
    font-size: 22px;
    font-weight: 700;
    color: #4B465C;
}

.icon-box {
    background: #F4F3FE;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.icon-box svg {
    width: 28px;
    height: 28px;
    color: #7367F0;
}

.bg-red {
    background: #FDF1F1;
}

.bg-red svg {
    color: #FF5B5B;
}

.bg-green {
    background: #EEFBF3;
}

.bg-green svg {
    color: #00C689;
}

.bg-yellow {
    background: #FFF7F0;
}

.bg-yellow svg {
    color: #FF9F43;
}

.mobile-text{
    display: none;
}

.mobile-text h1 {
    font-size: 18px;
    text-align: center;
    margin: 50px 50px 0px;
    color: #7367F0;
}

.mobile-text span{
    font-weight: 700;
}


@media only screen and (min-width:280px) and (max-width:992px) { 
    .dashboard-card {
        margin-bottom: 10px;
    }

    .pd-26 {
        padding: 16px 0px 0px;
    }
}


@media only screen and (min-width:993px) and (max-width:1200px) { 
    .dashboard-card {
        margin-bottom: 10px;
    }

    .pd-26 {
        padding: 16px 0px;
    }
}

@media (max-width: 767px) {
    .mobile-text{
        display: block;
    }

    .mob-none{
        display: none;
    }
}



.sc-bZQynM.hkrGSj {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
}


.loading {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    z-index: 999;
    opacity: 1;
}

.jaMWCU {
    display: flex !important;
    position: relative;
    align-items: center;
    height: 100vh !important;
    justify-content: center;
    color: #75B317;
    width: auto !important;
}

.jaMWCU div::after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #75B317 !important;
    margin: -3px 0px 0px -3px;
}