.bg-color {
    color: #75B317
}

.our-product-hero {
    background-image: url(../../assets/Images/bg-image/bo01.webp);
    background-size: cover;
    background-position: center, bottom;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center
}

.our-product-hero::before {
    content: "";
    position: absolute;
    background-color: #030014;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: .5;
    z-index: 1
}

.hero-text {
    overflow: hidden;
    z-index: 1;
}

/* .custom-container {
    z-index: 9999
} */

.bg-custom-title .bold-title {
    font-weight: 900
}

.bg-custom-title p {
    color: #fff;
    text-align: center;
    width: 800px;
    margin: 0 auto;
    font-size: 18px
}

.about-image-content {
    display: flex;
    align-items: center;
    justify-content: center
}

.product-img {
    height: 100%;
    width: 70% !important;
    background-image: url(../../assets/Images/bg-image/vector.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media (max-width:767px) {
    .our-product-hero {
        background-position: center;
        height: 350px
    }
}