body {
    background-color: #fbfbfb;
    color: #2C2C2C;
    font-family: 'Barlow', sans-serif;
    overflow-x: hidden;
}

.sidebar-container {
    min-width: 260px;
    position: fixed;
    height: -webkit-fill-available;
}

.main-container {
    width: 100%;
    margin: 10px 10px 0px 270px;
}


.pd-26 {
    padding: 26px 0px 0px;
}

.custom-pt-20 {
    padding-top: 20px;
}

.md-26 {
    margin: 26px 0px 0px;
}

/* .row {
    margin: 0px;
} */

.confirm-Delete-bttn {
    font-size: 16px;
    font-weight: 600;
    color: #fff !important;
    background-color: #ff0000 !important;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid #ff0000;
}

.delete-Cancel-bttn {
    font-size: 16px;
    font-weight: 600;
    color: #fff !important;
    background-color: #ccc !important;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid #ccc !important;
}

/* .row>* {
    padding: 0px !important;
} */

.bg-yellow-dark {
    background-color: #CFBA00;
}

.bg-green-dark {
    background-color: #75B317 !important;
    border-color: #75B317 !important;
}

.bg-red-dark {
    background-color: #FF0000 !important;
    border-color: #FF0000 !important;
}

.bg-blue-dark {
    background-color: #0080FF;
}


.main-container.new-class-name {
    margin: 22px 22px 0px 102px;
    transition: 0.3s;
}


.react-bootstrap-table {
    width: 100%;
    overflow: auto;
}

.react-calendar {
    width: 100%;
    margin-bottom: 10px;
}

.profile-img {
    width: auto;
    height: 120px;
    object-fit: cover;
}

.full-width-modal .modal-dialog {
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 100% !important;
    height: 100%;
    width: 100%;
}

.fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid #4b465c;
}


.m-b-50 {
    margin-bottom: 50px;
}

.dark-link {
    color: #333;
}

.heading-line {
    position: relative;
    padding-bottom: 5px;
}

.heading-line:after {
    content: "";
    height: 4px;
    width: 75px;
    background-color: #29B6F6;
    position: absolute;
    bottom: 0;
    left: 0;
}

.notification-ui_dd-content {
    margin-bottom: 30px;
}

.notification-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 7px;
    background: #fff;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
    align-items: center;
}

.icon-button__badge {
    position: absolute;
    top: -17px;
    right: -5px;
    width: 20px;
    height: 20px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}


.notification-list--unread {
    border-left: 2px solid #29B6F6;
}

.notification-list .notification-list_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
    margin-bottom: 5px;
    line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
    height: 48px;
    width: 48px;
    border-radius: 5px;
    margin-left: 20px;
}

.technician-date-time .daterangepickercontainer .daterangepicker .timeContainer {
    display: none !important;
}

@media only screen and (min-width:280px) and (max-width:992px) {
    .main-container {
        width: 100%;
        margin: 12px 12px 0px 12px;
    }

    .react-calendar {
        width: 100%;
        margin-bottom: 10px;
    }

    .booking-custom-lable .form-label {
        margin-bottom: 0.5rem;
        font-size: 14px;
    }
}

@media only screen and (min-width:993px) and (max-width:1200px) {
    .react-calendar {
        width: 100%;
        margin-bottom: 10px;
    }
}

.notification-container {
    z-index: 9999999999999 !important;
}


.content_404 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(../images/404\ bg.png);
}

.logo-404 img {
    width: 20%;
    height: auto;
    margin-bottom: 50px;
}

.logo-404,
.text-404 {
    text-align: center;
}

.text-404 img {
    width: 25%;
}

.sub-text {
    text-align: center;
    font-size: 60px;
    font-weight: 900;
    color: #fff;
    margin-top: 26px;
}

.btn-404 {
    text-align: center;
    margin: 20px 0px 0px 0px;
}

.go-back {
    background-color: #91de21;
    color: #1d2025;
}

.go-back:hover {
    background-color: #91de21;
    color: #1d2025;
}

span.text-bold-2 {
    font-weight: 700;
}