.logo-image {
    width: 200px;
    height: auto
}

.custom-container {
    margin: 0 160px;
    z-index: 1;
}

.nav-menu {
    font-size: 16px;
    font-weight: 700;
    color: #2C2C2C
}

.nav-menu .dropdown-toggle {
    color: #2C2C2C
}

.nav-link {
    padding-left: 20px !important;
}

.header-custom {
    position: fixed;
    width: 100%;
    z-index: 99999
}

.target-link {
    text-decoration: none !important
}

.modal-dialog {
    max-width: 600px !important
}

.modal-header {
    background: #DEDEDE;
    padding: 16px 20px
}

.modal-content {
    border-radius: 25px
}

.modal-header {
    background: #DEDEDE;
    padding: 16px 20px;
    border-radius: 25px 25px 0 0
}

.modal-header .btn-close {
    background-color: #fff;
    opacity: 1;
    border-radius: 50%;
    padding: 8px
}

.modal-body2 {
    padding: 40px 70px 60px;
}

.modal-tltle {
    text-align: center;
    margin-bottom: 50px;
    font-size: 33px;
    font-weight: 900
}

.custom-lable {
    font-weight: 700;
    font-size: 15px
}

.custom-input {
    height: 50px;
    border: 1px solid #DBDADE
}

.Login-btn-view {
    background: #75b317 !important;
    border: 1px solid #75b317 !important;
    width: 100%;
    padding: 16px;
    font-size: 18px;
    font-weight: 600
}

.Login-btn-view:hover {
    background: #75b317;
    border: 1px solid #75b317
}

.modal.show {
    z-index: 9999999
}

@media (max-width:991px) {
    .logo-image {
        width: 160px;
        height: auto
    }
}

@media only screen and (min-width:1024px) and (max-width:1300px) {
    .nav-link {
        padding-left: 20px !important
    }
}