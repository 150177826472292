.navbar {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.075) !important;
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 0px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    margin-bottom: 0px;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.custom-header-nav-menu {
    display: flex;
    gap: 20px;
    list-style: none;
    text-align: center;
    /* / width: 70vw; / */
    justify-content: end;
    margin-right: 2rem;
    margin-bottom: 0 !important;
    align-items: center;
}

.nav-links {
    color: #2c2c2c !important;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
}

.nav-links:hover {
    transition: all 0.2s ease-out;
    color: #75b317;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.header-dropdwn button.dropdown-toggle {
    padding: 0px 5px;
    background-color: transparent !important;
    border: none;
    font-size: 16px;
    font-weight: 700;
}

.header-dropdwn {
    padding: 8px 1rem !important;
    border-radius: 4px;
    background-color: transparent;
}

.header-dropdwn:hover {
    transition: all 0.2s ease-out;
}

a.dropdown-item.hover-new:hover {
    color: #75b317;
    background-color: transparent;
}

.header-dropdwn:hover button.dropdown-toggle {
    color: #75b317;
}

.header-login-btn {
    background: transparent !important;
    border: none;
    font-size: 17px;
    font-weight: 700;
    box-shadow: none !important;
}

.header-login-btn:hover {
    color: #000;
    transition: all 0.2s ease-out;
    background: transparent !important;
}

.home-drop-show {
    transform: translate(0px, 46px) !important;
}

.custom-header-nav-menu li.active a {
    color: #75b317;
}

.cstm-navbar .custom-header-nav-menu li {
    height: 70px;
    align-items: center;
    display: flex;
    border-bottom: 3px solid transparent;
}

.cstm-navbar .custom-header-nav-menu li:hover,
.cstm-navbar .custom-header-nav-menu li.active {
    border-bottom: 3px solid #75b317;
}

.login-btn-view {
    background-color: #75b317 !important;
    color: #fff !important;
    border-radius: 4px;
    padding: 10px 2rem;
    font-size: 16px;
    font-weight: 700;
    box-shadow: none !important;
    border: 1px solid #75b317;
}

.login-btn-view:hover {
    background-color: #fff !important;
    color: #75b317 !important;
    border: 1px solid #75b317;
    transition: all 0.2s ease-out;
}

.dash-drop .nav-menu svg {
    width: 18px;
    height: 18px;
}

.dash-drop .nav-menu:hover {
    background-color: #75b317;
    color: #fff !important;
    transition: all 0.2s ease-out;
}

.custom-model .modal-dialog {
    max-width: 550px !important;
}

span.seperator {
    padding: 2rem 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

span.seperator:after,
span.seperator:before {
    background-color: #2c2c2c;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 22%;
}

span.seperator:before {
    right: .5em;
    margin-left: -50%;
}

span.seperator:after {
    left: .5em;
    margin-right: -50%;
}

.form-btn-mini {
    width: 100%;
    padding: 0 30px;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    z-index: 1;
    outline: 0;
    box-shadow: 0 8px 14px 0 rgb(157 157 157 / 24%);
}

.btn-green {
    background-color: #75b317;
    color: #fff;
}

.btn-green:hover {
    color: #ffffff;
}

.btn-dark {
    background-color: #2c2c2c;
    color: #fff;
}

.btn-dark:hover {
    color: #ffffff;
    background-color: #2c2c2c;
}

@media (max-width: 991.98px) {
    nav.navbar {
        position: fixed;
        width: 100%;
        height: 70px;
        z-index: 999;
    }

    /* .header-dropdwn{
        padding: 0px !important;
    } */

    .cstm-navbar .custom-header-nav-menu li {
        height: 46px;
    }

    .header-dropdwn button.dropdown-toggle {
        padding: 0px;
    }

    .cstm-navbar .custom-header-nav-menu li:hover,
    .cstm-navbar .custom-header-nav-menu li.active {
        border-bottom: 3px solid transparent;
    }

    .custom-header-nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 100%;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        gap: 5px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .header-dropdwn {
        padding: 8px 0rem !important;
    }

    .custom-header-nav-menu.active {
        background: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: left;
        padding: 1rem 0;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #7577fa;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(18%, 30%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 30%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #6568f4;
        transition: 250ms;
    }
}

@media only screen and (min-width: 280px) and (max-width: 425px) {
    .modal-body {
        padding: 22px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
    .custom-model {
        width: 100% !important;
        padding-left: 0 !important;
    }

    .custom-model .modal-dialog {
        max-width: 100% !important;
        margin: 0;
    }

    .custom-model .modal-content {
        border-radius: 0;
        min-height: 100vh;
        border: none;
    }

    .custom-model .modal-header {
        border-radius: 0;
        padding: 0 20px;
        min-height: 60px;
    }

    .custom-model .modal-tltle {
        font-size: 22px;
        margin: 0 0 2rem !important;
    }

    .custom-model .modal-logo img {
        width: 70%;
    }

    span.seperator {
        font-size: 14px;
        padding: 1.5rem 0;
    }

    .modal-body2 {
        padding: 40px 36px 60px !important;
    }

    .Login-btn-view {
        padding: 12px !important;
    }

    .form-btn-mini {
        padding: 0 20px;
        font-size: 15px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
    .form-btn-mini {
        padding: 0 16px;
        font-size: 14px;
    }
}