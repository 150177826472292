.tab-content-body {
    background: #fff;
    padding: 18px;
}

.tab-card {
    /* border-right: 1px solid #D9D9D9; */
    padding-right: 10px;
}

.bx-1 {
    border-left: 1px solid #D9D9D9;
}

.tab-card-header {
    justify-content: space-between;
    align-items: baseline;
}

h5.tab-card-title {
    font-size: 20px;
    font-weight: 700;
}

.action-btn {
    color: #fff !important;
    padding: 8px 30px;
    margin-right: 8px;
    border-radius: 6px;
}

.action-btn:hover {
    color: #fff;
}


.btn-green:hover {
    background: #75B317 !important;
}

.btn-red:hover {
    background: #FF0000 !important;
}

.nav-pills .technician-tab-link.active,
.nav-pills .show>.technician-tab-link {
    color: #75B317;
    background-color: transparent !important;
    padding: 8px 0px;
}

.user-profile-box {
    background: #F1F0F2;
    padding: 6px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
}

.technician-tab-link {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    color: #000;
}

.profile-image {
    width: 26px;
    height: 26px;
}

span.tab-user-name {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
}

.tab-content-header {
    margin-left: 18px;
}

/* .technician-tab-link .active.user-profile-box {
    background: #75B317;
} */

.tab-content-body .nav-tabs {
    border-bottom: 1px solid #D9D9D9;
}

.tab-content-body .nav-tabs .nav-item .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.tab-content-body .nav-tabs .nav-item .nav-link:hover {
    border: none;
    border-color: transparent;
}

.tab-content-body .nav-tabs .nav-item .nav-link.active {
    color: #75B317;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 2px solid #75B317;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}


.form-btn-right {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 20px; */
}

.card-custom {
    padding: 24px 0px;
}


.schedule-list-item-inner {
    border: 1px solid #D9D9D9;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-list-item-inner-right-top {
    display: flex;
    align-items: center;
}

.spacing {
    margin: 0px 8px;
}

.schedule-list-item-inner-left-top-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.add-offtime-row {
    align-items: end;
}

.off-time-detail-btn {
    float: right;
}

.off-time-detail-btn .action-btn {
    margin-right: 0px;
}

.user-profile-box svg {
    width: 24px;
    height: 24px;
}

.add-btn {
    justify-content: space-between;
    align-items: center;
}

.payment-list-item {
    border: 1px solid #DBDADE;
    border-radius: 6px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-list-title h4 {
    font-size: 19px;
    margin-bottom: 0px;
    font-weight: 600;
}


.custom-accordion-item {
    border-top: 1px solid #DBDADE !important;
    border-bottom: 1px solid #DBDADE !important;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
}

.accordion-button {
    color: #6F6B7D;
    font-weight: 600;
}

.accordion-button:not(.collapsed) {
    color: #6F6B7D;
    background-color: #fff;
    border-color: #DBDADE;
}

.accordion-button:focus {
    box-shadow: none;
}



.setting-btn {
    color: #fff !important;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 8px;
}

.setting-btn:hover {
    color: #fff;
}

.bg-green-dark:hover {
    background: #75B317 !important;
}

.bg-lightblue-dark {
    background: #8178DB !important;
}

.bg-lightblue-dark:hover {
    background: #8178DB !important;
}

.bg-purple-dark {
    background: #B31799 !important;
}

.bg-purple-dark:hover {
    background: #B31799 !important;
}

.accordion {
    padding: 0px !important;
}

.accordion-body {
    padding: 16px 10px;
}

.accordian-header-title-small h4 {
    font-size: 20px;
    font-weight: 600;
}

button.tag-btn-data {
    background: transparent;
    border: none;
    color: #8178db;
    font-size: 17px;
    font-weight: 500;
}

@media only screen and (min-width:426px) and (max-width:992px) {


    .tab-content-body .nav-tabs .nav-item .nav-link.active {
        color: #75B317;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 2px solid #75B317;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }

    .tab-content-body .nav-tabs .nav-item .nav-link {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        padding: 0px;
    }

    .setting-btn {
        color: #fff !important;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 500;
        margin-right: 0px;
    }
}


@media only screen and (min-width:993px) and (max-width:1200px) {

    .tab-content-body .nav-tabs .nav-item .nav-link.active {
        color: #75B317;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 2px solid #75B317;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .tab-content-body .nav-tabs .nav-item .nav-link {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        padding: 0px;
    }

    .setting-btn {
        color: #fff !important;
        padding: 8px 8px;
        font-size: 14px;
        font-weight: 500;
        margin-right: 0px;
    }
}