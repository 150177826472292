.did-floating-label-content {
    position: relative;
    margin-bottom: 20px;
}

.did-floating-label {
    color: #1e4c82;
    font-size: 13px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 13px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.did-floating-file,
.did-floating-select {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 46px;
    padding: 15px 20px;
    background: #fff;
    color: #323840;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: none;

        ~.did-floating-label {
            top: -8px;
            font-size: 13px;
        }
    }
}

.did-floating-color,
.did-floating-select {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 46px;
    padding: 15px 20px;
    background: #fff;
    color: #323840;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: none;

        ~.did-floating-label {
            top: -8px;
            font-size: 13px;
        }
    }
}

.did-floating-textarea,
.did-floating-select {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 70px;
    padding: 12px 20px 0px;
    background: #fff;
    color: #323840;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: none;

        ~.did-floating-label {
            top: -8px;
            font-size: 13px;
        }
    }
}

.did-floating-input,
.did-floating-select {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 46px;
    padding: 0 20px;
    background: #fff;
    color: #323840;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: none;

        ~.did-floating-label {
            top: -8px;
            font-size: 13px;
        }
    }
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.did-floating-select::-ms-expand {
    display: none;
}

.did-floating-textarea:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-file:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-color:not(:placeholder-shown)~.did-floating-label {
    top: -11px;
    font-size: 13px;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-select[value=""]:focus~.did-floating-label {
    top: 11px;
    font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.did-error-input {

    .did-floating-input,
    .did-floating-select {
        border: 2px solid #9d3b3b;
        color: #9d3b3b;
    }

    .did-floating-label {
        font-weight: 600;
        color: #9d3b3b;
    }

    .did-floating-select:not([multiple]):not([size]) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
    }
}

.input-group {
    display: flex;

    .did-floating-input {
        border-radius: 0 4px 4px 0;
        border-left: 0;
        padding-left: 0;
    }
}

.input-group-append {
    display: flex;
    align-items: center;
    /*   margin-left:-1px; */
}

.did-floating-textarea {}

.input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 40px;
    color: #b62e2e;
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #d3d3d3;
    border-radius: 4px 0 0 4px;
    border-right: none;
}

.rbt-input-multi .rbt-input-wrapper {
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
    height: 100%;
}


.did-floating-select-custom .rbt-input-multi.focus {
    box-shadow: none;
}

.did-floating-select-custom .rbt-input-multi {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 46px !important;
    padding: 0 20px;
    background: #fff;
    color: #323840;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-sizing: border-box;
}

.did-floating-select-custom .rbt-input-multi.focus {
    outline: none;
}

.did-floating-select-custom .rbt-input-multi.focus ~ .did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-select-custom .rbt-input-multi:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 13px;
}


.did-floating-color {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 46px !important;
    padding: 8px 12px !important;
    background: #fff;
    color: #323840;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-sizing: border-box;
}

.did-floating-input:focus, .did-floating-select:focus {
    box-shadow: none;
    border-color: #dee2e6;
}

.address .did-floating-textarea  {
    height: max-content !important;
}