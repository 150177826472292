.tab-content-body {
    background: #fff;
    padding: 18px;
}

.tab-card {
    /* border-right: 1px solid #D9D9D9; */
    padding-right: 10px;
}

.bx-1 {
    border-left: 1px solid #D9D9D9;
}

.tab-card-header {
    justify-content: space-between;
    align-items: baseline;
}

h5.tab-card-title {
    font-size: 20px;
    font-weight: 700;
}

.action-btn {
    color: #fff !important;
    padding: 8px 34px;
    margin-right: 12px;
    border-radius: 6px;
}

.action-btn:hover {
    color: #fff;
}


.btn-green:hover {
    background: #75B317 !important;
}

.btn-red:hover {
    background: #FF0000 !important;
}

.nav-pills .technician-tab-link.active,
.nav-pills .show>.technician-tab-link {
    color: #75B317;
    background-color: transparent !important;
    padding: 8px 0px;
}

.user-profile-box {
    background: #F1F0F2;
    padding: 6px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
}

.technician-tab-link {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    color: #000;
}

.profile-image {
    width: 26px;
    height: 26px;
}

span.tab-user-name {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
}

.tab-content-header {
    margin-left: 18px;
}

/* .technician-tab-link .active.user-profile-box {
    background: #75B317;
} */

.tab-content-body .nav-tabs {
    border-bottom: 1px solid #D9D9D9;
}

.tab-content-body .nav-tabs .nav-item .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.tab-content-body .nav-tabs .nav-item .nav-link:hover {
    border: none;
    border-color: transparent;
}

.tab-content-body .nav-tabs .nav-item .nav-link.active {
    color: #75B317;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 2px solid #75B317;
    border-top: none;
    border-left: none;
    border-right: none;
}


.form-btn-right {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 20px; */
}

.card-custom {
    padding: 24px 0px;
}


.schedule-list-item-inner {
    border: 1px solid #D9D9D9;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-list-item-inner-right-top {
    display: flex;
    align-items: center;
}

.spacing {
    margin: 0px 8px;
}

.schedule-list-item-inner-left-top-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.add-offtime-row {
    align-items: end;
}

.off-time-detail-btn {
    float: right;
}

.off-time-detail-btn .action-btn {
    margin-right: 0px;
}

.card-custom-mr {
    margin-left: 12px;
}


.btn-list .action-btn {
    color: #fff !important;
    padding: 8px 24px;
    margin-right: 12px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
}

.technician-tab-link:focus {
    color: #75b317;
}

.month-name {
    margin: 10px 0px;
    background: #75b317;
    text-align: center;
    border-radius: 5px;
}

button.month-btn {
    border: none;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 500;
    color: #2c2c2c;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    width: 100%;
}

.no-technication {
    position: absolute;
    top: 50%;
    left: 57%;
    font-size: 22px;
    font-weight: 700;
}

.daterangepicker {
    top: 40px !important;
}

.booking-custom-lable {
    width: 100%;
}

.technician-tab-link {
    width: 100% !important;
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 8px 0px !important;
    background-color: transparent !important;
    border-radius: 0px !important;
}

.technician-tab-link:hover svg {
    color: #fff !important;
}

.tab-card-body {
    height: 90vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.bg-red-dark.disabled {
    pointer-events: all;
}

.bg-green-dark.disabled {
    pointer-events: all;
}

.name-newline {
    font-size: 14px;
    font-weight: 500;
    margin-left: 16px;
}

.card-header-custom {
    display: flex;
    align-items: center;
    gap: 10px;
}

.new-line {
    display: flex;
}


@media only screen and (min-width:426px) and (max-width:992px) {
    .btn-list {
        display: flex;
        justify-content: space-between;
    }

    .btn-list .action-btn {
        color: #fff !important;
        padding: 5px 14px;
        margin-right: 8px;
        border-radius: 6px;
    }


    .tab-content-body {
        background: #fff;
        padding: 18px 0px;
    }

}


@media only screen and (min-width:993px) and (max-width:1200px) {
    .btn-list {
        display: flex;
        justify-content: space-between;
    }

    .btn-list .action-btn {
        color: #fff !important;
        padding: 5px 24px;
        margin-right: 8px;
        border-radius: 6px;
    }

    .tab-content-body .nav-tabs .nav-item .nav-link.active {
        color: #75B317;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 2px solid #75B317;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .tab-content-body .nav-tabs .nav-item .nav-link {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        padding: 0px;
    }


}