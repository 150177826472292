.nav-pills .nav-link.Booking-tab.active {
    background: #75b317;
    padding: 10px 0px !important;
    border-radius: 30px;
    color: #fff;
    margin: 10px 0;
    font-size: 15px;
    font-weight: 700;
    min-width: 168px;
    width: auto !important
}

.nav-pills .nav-link.Booking-tab {
    background: #fff;
    padding: 10px 0px !important;
    border-radius: 30px;
    color: #2c2c2c;
    margin: 10px 0;
    font-size: 15px;
    font-weight: 700;
    min-width: 168px;
    width: auto !important
}

.dflt-mt-top {
    margin-top: 20px
}

.booking-list nav ul,
.payment-settings-content-body nav ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.booking-list nav ul li {
    border-radius: 10px;
    padding: 20px;
    background-color: #fff
}

.booking-list-number h1 {
    margin-bottom: 80px
}

.booking-list-number h1 {
    margin-bottom: 80px;
    background-color: #75B317;
    display: flex;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px
}

.order-id h6 {
    color: #75b317;
    font-size: 14px
}

.order-text p {
    font-size: 13px
}

.order-type h4 {
    font-size: 25px;
    font-weight: 700
}

.booking-actions button.Active-bttn,
.booking-actions button.Active-bttn:hover {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #75B317;
    width: 200px;
    padding: 12px 0
}

.booking-actions button.Cancel-bttn,
.booking-actions button.Cancel-bttn:hover {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: red;
    width: 200px;
    padding: 12px 0
}

.booking-list nav ul li {
    margin-bottom: 30px;
    border: 1px solid #eaeaea
}

.payment-settings {
    background-color: #fff;
    padding: 24px;
    border-radius: 10px
}

.transaction-History {
    background-color: #fff;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #eaeaea
}

.payment-settings-content-header h3,
.transaction-History-content-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0
}

.payment-settings-content-header button.Add-bttn {
    color: #fff;
    background-color: #75b317;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px
}

.payment-settings-content-header button.Add-bttn span {
    margin-right: 10px
}

.payment-settings-content-body nav ul li {
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #dbdade;
    margin-bottom: 16px
}

.Card-type-img img {
    width: auto;
    height: 26px;
    margin-bottom: 20px
}

.Card-Name h4 {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px
}

.Card-number p {
    font-size: 15px;
    font-weight: 300
}

.Card-Exp-date p {
    margin: 45px 0 0;
    font-size: 15px;
    text-align: end
}

.Edit-bttn {
    color: #7367F0 !important;
    background-color: #E8E6FC !important;
    padding: 10px 20px;
    font-weight: 500
}

.Delete-bttn {
    color: #A8AAAE !important;
    background-color: #F1F1F2 !important;
    padding: 10px 20px;
    font-weight: 500
}

table.Transacrion-Table thead {
    background-color: #F4F4F5
}

table.Transacrion-Table thead tr th {
    font-size: 17px;
    padding: 12px;
    font-weight: 600
}

table.Transacrion-Table tbody tr td {
    padding: 12px
}

table.Transacrion-Table {
    border: snow
}

table.Transacrion-Table .Price-bold {
    font-weight: 600
}

.invoice-download {
    border: none;
    font-size: 15px;
    color: #75b317;
    background-color: transparent
}

.custom-txt h6 {
    margin-bottom: 0;
    margin-left: 10px
}

.form-check-input:checked {
    background-color: #75b317;
    border-color: #75b317;
    /* padding: 10px 20px */
}

.form-check-input {
    background-color: transparent;
    border-color: #2c2c2c;
    /* padding: 10px 20px */
}

.dashboard-tab-list {
    display: flex;
    align-items: center;
    justify-content: center
}

.bookings-service-listed {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.hide-desktop {
    display: none
}

.booking-actions {
    text-align: right
}

.booking-list-number {
    display: flex;
    gap: 20px;
}

.booking-list-content {
    max-width: 80%;
}

.booking-actions {
    width: min-content;
}

.booking-actions2 {
    width: auto;
}

.Delete-bttn.denger {
    color: #fff !important;
    background-color: red !important;
    font-weight: 500;
}

.text-align-right {
    display: flex;
    justify-content: end;
}

@media (max-width:767px) {
    .Profile-card {
        display: none
    }

    .booking-actions .Active-bttn {
        margin-right: 10px
    }

    .show-mobile {
        display: block
    }

    .hero-bottom-margin {
        margin: 0 10px
    }

    .dashboard-tab-list .nav {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: scroll
    }

    .bookings-service-listed {
        display: block
    }

    .order-text {
        margin-right: 0;
        width: 100%
    }

    .dashboard-header-bg {
        margin: 0;
        width: 100%
    }

    .Topnavigation-container {
        margin: 0
    }

    .Herosection-container {
        margin: 0
    }

    .hero-bottom {
        margin: 0;
        border-radius: 0;
        padding: 10px 0
    }

    .booking-list-number h1 {
        margin-bottom: 10px
    }

    .booking-actions {
        text-align: left
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .Profile-card {
        display: none
    }

    .show-mobile {
        display: block
    }

    .Topnavigation-container {
        margin: 0
    }

    .Herosection-container {
        margin: 0
    }

    .dashboard-header-bg {
        margin: 0;
        width: 100%
    }

    .hero-bottom {
        margin: 0;
        border-radius: 0;
        padding: 10px 0
    }

    .hero-bottom-margin {
        margin: 0 10px
    }

    .booking-actions {
        text-align: right
    }
}

@media only screen and (min-width:993px) and (max-width:1199px) {
    .Profile-card {
        display: none
    }

    .show-mobile {
        display: block
    }

    .Topnavigation-container {
        margin: 0
    }

    .Herosection-container {
        margin: 0
    }

    .dashboard-header-bg {
        margin: 0;
        width: 100%
    }

    .hero-bottom {
        margin: 0;
        border-radius: 0;
        padding: 10px 0
    }

    .hero-bottom-margin {
        margin: 0 10px
    }

    .booking-actions {
        text-align: right
    }
}

@media only screen and (min-width:280px) and (max-width:992px) {
    .profile-show-mobile {
        display: block
    }
}

@media only screen and (min-width:1199.98px) and (max-width:2800px) {
    .profile-show-mobile {
        display: none
    }
}

@media only screen and (min-width:280px) and (max-width:575.98px) {

    table.Transacrion-Table tbody tr td,
    table.Transacrion-Table thead tr th {
        padding: 8px;
        font-size: 12px
    }

    .transaction-History,
    .payment-settings {
        padding: 24px 10px
    }

    .invoice-download {
        font-size: 12px
    }

    .payment-settings-content-body nav ul li {
        padding: 12px
    }

    .Card-Exp-date p {
        margin: 24px 0 0
    }
}

@media (max-width:478.98px) {
    table.Transacrion-Table tbody tr td,
    table.Transacrion-Table thead tr th {
        padding: 4px;
        font-size: 10px
    }
}