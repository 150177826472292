.select-box-list {
    display: flex;
    padding: 0 !important;
}

.select-box-list-item {
    margin: 0px 10px;
    width: 100%;
}

.xxl-2 {
    margin-right: 10px;
}

.appointment-accordion {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

.appointment-accordion .accordion-button:not(.collapsed) {
    color: #669c14;
    background-color: #fff;
    border-color: #DBDADE;
    font-size: 18px;
    font-weight: 700;
}

.form-title h4 {
    font-size: 20px;
    color: #2c2c2c;
    font-weight: 600;
}

.form-title {
    margin: 18px 0px;
}

.Service-details-box-inner-title {
    font-size: 20px;
    color: #75b317;
    font-weight: 700;
    margin-bottom: 0px;
}

.custom-select-box {
    border: 1px solid #EDEDED;
    height: 44px
}

.Service-details-box-inner.extra-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fbfbfb;
    padding: 10px 12px
}

.custom-cheack-box-view {
    margin-right: 0.4rem;
}

.custom-cheack-box-view .form-check-input {
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
}

.custom-cheack-box-view .form-check-input:checked {
    background-color: #75b317;
    border-color: #75b317;
}

.custom-cheack-box-view .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(101 155 19 / 19%);
}

.booking-warrper-20 {
    margin-top: 20px;
}

.add-item-btn {
    background: #75b317 !important;
    border: 1px solid #75b317 !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px
}

.add-item-btn:hover {
    background: #75b317;
    border: 1px solid #75b317
}

.extra-service-value {
    margin: 0 14px;
    font-size: 20px;
    font-weight: 600
}

.estimated-box {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 6px;
}

.estimated-title {
    text-align: center;
}

.estimated .icon {
    color: #75b317;
    margin-right: 8px;
}

.estimated .icon svg {
    width: 24px;
    height: 24px;
}

.estimated .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.estimated .sub-title {
    margin-right: 8px;
}

.estimated {
    margin: 14px 0px;
    align-items: center;
}

.form {
    width: 50%;
}

.estimated-head {
    margin-bottom: 20px;
}

.appointment-modal-btn {
    background: #2c2c2c;
    color: #fff;
    border-color: #2c2c2c;
}

.appointment-modal-btn:hover {
    background: #2c2c2c;
    color: #fff;
    border-color: #2c2c2c;
}

.g-btn {
    background: #689f14 !important;
    color: #fff !important;
    font-weight: 500;
    padding: 7px 14px;
}

.g-btn:hover {
    background: #689f14;
    color: #fff;
    font-weight: 500;
    padding: 7px 14px;
}

.b-btn {
    background: rgb(8, 104, 172) !important;
    color: #fff !important;
    padding: 7px 14px;
    font-weight: 500;
    float: right;
}

.b-btn:hover {
    background: rgb(8, 104, 172);
    color: #fff;
    padding: 7px 14px;
    font-weight: 500;
}

.btn-header-list {
    display: flex;
    justify-content: space-between;
}

.booking-status {
    display: flex;
    margin: 14px 0px;
}

.booking-status-view p {
    margin-bottom: 0px;
    color: #689f14;
    font-weight: 600;
}

ul.ct-cal-booking-details {
    list-style: none;
    padding-left: 0px;
}

li.ct-cal-booking-details-item {
    padding: 4px 0px;
}

li.ct-cal-booking-details-item label {
    width: 40%;
}

.w-40 {
    width: 40%;
}

.ct-customer-details-hr {
    font-size: 20px;
    font-weight: 600;
}

.view-more-content {
    color: #0868ac !important;
    padding: 0px;
    font-weight: 500;
    background: transparent !important;
    border: none !important;
}

.view-more-content:hover {
    color: #0868ac !important;
    padding: 0px;
    font-weight: 500;
    background: transparent !important;
}

.view-more-content-hide {
    display: none;
}

.view-more-content-hide.active {
    display: block;
}

.button-group1 {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: end;
    width: 100%;
    gap: 10px;
}

.modal-footer .button-group1 .btn {
    width: auto !important;
    margin-bottom: 10px;
}

/* .modal-footer .button-group1 .btn:last-child{
    margin-bottom: 0px;
} */

.modal-footer .button-group1 .btn svg {
    margin-right: 8px;
}

.confirm-Active-bttn {
    background: #689f15 !important;
    border-color: #689f15 !important;
}

.confirm-Active-bttn:hover {
    background: #689f15 !important;
    border-color: #689f15 !important;
}

.reschedule-Active-bttn {
    background: #0868ac !important;
    border-color: #0868ac !important;
}

.reschedule-Active-bttn:hover {
    background: #0868ac !important;
    border-color: #0868ac !important;
}

.reject-Active-bttn {
    background: #2c3e50 !important;
    border-color: #2c3e50 !important;
}

.reject-Active-bttn:hover {
    background: #2c3e50 !important;
    border-color: #2c3e50 !important;
}

.less-btn {
    display: none;
}

.less-btn.active {
    display: block;
}

.more-btn {
    display: block;
}

.more-btn.active {
    display: none;
}

.existing-user {
    display: none;
}

.existing-user.active {
    display: block;
}

.userdata {
    display: none;
}


.password.clicknone {
    display: block !important;
}

.password {
    display: none;
}

.userdata.newuser {
    display: block !important;
}

.appointment_details {
    display: block !important;
}

.appointment_details {
    display: none;
}

.bookingData {
    display: block;
}

.bookingData.active {
    display: block;
}

.bookingData_2 {
    display: block;
}

.bookingData_2.active {
    display: block;
}

.appointment_details.active {
    display: block !important;
}


.ct-cal-error {
    border: 2px solid red;
}

.fc-event-title.fc-sticky {
    color: #000;
}

.fc-timeline-event .fc-event-time,
.fc-timeline-event .fc-event-title {
    padding: 0 2px;
    white-space: nowrap;
    color: #000;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
    background-color: #75b317;
    border-color: #75b317;
    color: var(--fc-button-text-color);
}

.modal-button-content {
    border: 1px solid #75b317 !important;
    margin: 0px 30px 0px 0px;
    height: 48px;
    border-radius: 0px;
    width: 44%;
}

.modal-button-content:hover {
    border: 1px solid #75b317 !important;
    height: 48px;
    border-radius: 0px;
}

.modal-button-content.active {
    box-shadow: 0px 3px 0px #75b317
}

.recurring-modal-content {
    display: none;
}

.recurring-modal-content.active {
    display: block;
}

.modal-content-inner-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-inner-title h3 {
    font-size: 20px;
}

.modal-content-inner {
    padding: 0px 8px;
}

.summary-text {
    font-weight: 600;
}

.summary-text {
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding: 0px 0px 14px;
}

.modal-content-inner-footer {
    padding-top: 20px;
    display: flex;
    justify-content: right;
}

.week-days a {
    border: 12px;
    display: block;
    padding: 5px 7px;
    font-size: 14px;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 1px 1px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset !important;
    background-color: #fff;
    color: black;
}

.week-days span {
    cursor: pointer;
    border: 12px;
    display: block;
    padding: 5px 7px;
    font-size: 14px;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 1px 1px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset !important;
    background-color: #fff;
    color: black;
}

.modal-content-inner-body {
    display: grid;
}

.week-days h5 {
    font-size: 14px;
}

.month-list a.month {
    color: black;
    text-align: center;
    width: 15px;
    padding: 2px 8px;
    font-size: 12px;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 1px 1px;
    margin: -1px -4px 0 -1px;
    line-height: 10px;
    background-color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.custom-recu a {
    border: 12px;
    display: block;
    padding: 5px 7px;
    font-size: 14px;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 1px 1px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset !important;
    background-color: #fff;
    color: black;
}

.my_time_week {
    width: 25%;
}

.mode_type {
    width: 40%;
}

.cust_recurning {
    display: flex;
}

.weeks.slected-weeks {
    background-color: #75b317;
    color: #fff;
}



.fc .technicianavailability-btn {
    border-radius: 0 .25em .25em 0;
}

@media only screen and (min-width:280px) and (max-width:992px) {
    .select-box-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .select-box-list-item {
        margin: 0px 10px;
        width: 47%;
        margin-bottom: 10px;
    }
}


@media only screen and (min-width:993px) and (max-width:1226px) {
    .select-box-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .select-box-list-item {
        margin: 0px 10px;
        width: 30%;
        margin-bottom: 10px;
    }
}

.bookingData.danger {
    border: 1px solid red;
}

.fc-event-main b {
    color: #000;
    font-weight: 400;
}

.fc-event-main b i {
    margin: 0px 6px;
}

.modal-dialog {
    max-width: 966px !important;
}

.fc-license-message {
    display: none;
}

.confirm-Active {
    background: #689f15 !important;
    border-color: #689f15 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px;
    padding: 0px 7px 5px;
    font-size: 20px;
}

.resourcbackground-6 {
    background-color: rgb(214, 234, 248);
}

.resourcbackground-9 {
    background-color: rgb(253, 235, 208);
}

.resourcbackground-10 {
    background-color: rgb(253, 235, 208);
}

.resourcbackground-7 {
    background-color: rgb(232, 218, 239);
}

.css-1nmdiq5-menu {
    z-index: 999 !important;
}

.table-invoice tr {
    border: 1px solid #ccc;
    border-radius: 2px;
    margin-bottom: 14px;
}

.table-invoice thead tr {
    border: none !important;
}

.table-invoice tr td {
    border: none !important;
}

.card-title-invoice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}

.card-title-invoice h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
}

.add-invoice-btn .btn-invoice {
    color: #fff;
    background-color: #75b317;
    border: 1px solid #75b317;
    padding: 5px 10px;
    border-radius: 5px;
}

.add-invoice-btn .btn-invoice svg {
    font-size: 20px;
}

button.remove-row-btn {
    position: absolute;
    background: 0;
    border: none;
    padding: 0;
    right: 26px;
}

.price-width {
    width: 100px;
}

.Delete-Active-bttn {
    position: relative;
}

.confirm-delete-modal {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 300px;
    padding: 1px;
    display: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    line-break: auto;
}

.confirm-delete-modal.show {
    display: block;
    top: -110px;
    left: -67.8594px;
}

.delete-btn {
    width: auto;
    position: relative;
}

.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

.popover-content {
    padding: 9px 14px;
}

.confirm-delete {
    padding: 5px 10px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    background-image: linear-gradient(to bottom, #d9534f 0, #c12e2a 100%);
}

.cancel-delete {
    padding: 5px 10px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    background-image: linear-gradient(to bottom, #ffffff 0, #e0e0e0 100%);
    color: #000;
}

.btn-list {
    display: flex;
    justify-content: space-evenly;
}

.fc-timeline-event .fc-event-main {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap !important;
}


button.minimize-btn-inner {
    padding: 10px;
    color: #ffffff;
    background: #75b317;
    border-radius: 5px;
    border: 1px solid #75b317;
    font-weight: 600;
    font-size: 30px;
    display: flex;
    /* align-items: center; */
}

button.minimize-btn-inner:hover {
    color: #ffffff;
    background: #75b317;
    transform: scale(1.2);
}

.minimize-btn {
    position: fixed;
    bottom: 30px;
    right: 70px;
    z-index: 11111;
}

button.minimize-btn-header{
    background: #161f28;
    border: none;
    padding: 0;
    margin-right: 10px;
    line-height: 0.3;
}

button.minimize-btn-header svg {
    height: 30px;
    width: 30px;
    color: #ffffff;
}