.logo-icon {
    width: 300px;
    height: auto;
}

.color-text {
    color: #75B317;
}

.login-head {
    color: #4B465C;
    font-size: 26px;
    font-weight: 700;
}

.custom-login-btn {
    background: #75b317 !important;
    color: #fff !important;
    width: 100%;
    font-weight: 500;
    border: 1px solid #75b317 !important;
}

.custom-login-btn:hover {
    background: #75b317;
    color: #fff;
}